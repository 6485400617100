import React from "react"
import FluidBackground from "@components/FluidBackground"
import Section from "@components/Section"
import Logo from "@components/Logo"
import LanguageSwitch from "@components/LanguageSwitch"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import linkResolver from "../utils/linkResolver"
import ContactButton from "@components/ContactButton"
import ModalProvider from "../providers/modal"
import SEO from "@components/seo"
import Footer from "../sections/footer"

const Oups = () => (
  <svg
    className="w-full h-auto"
    viewBox="0 0 384 176"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M135.506 139.44C130.514 139.44 125.858 138.384 121.538 136.272C117.314 134.16 113.906 130.992 111.314 126.768C108.818 122.448 107.57 117.072 107.57 110.64V68.016H116.21V110.064C116.21 117.648 118.13 123.168 121.97 126.624C125.81 129.984 130.946 131.664 137.378 131.664C144.578 131.664 150.578 129.312 155.378 124.608C160.178 119.808 162.578 112.608 162.578 103.008V68.016H171.218V138H162.866V125.04H161.138C159.41 128.784 156.578 132.144 152.642 135.12C148.706 138 142.994 139.44 135.506 139.44Z"
      fill="#212121"
    />
    <path
      d="M196.61 166.8V68.016H204.962V80.832H206.69C208.706 76.992 211.826 73.584 216.05 70.608C220.37 67.536 226.37 66 234.05 66C240.29 66 245.954 67.488 251.042 70.464C256.13 73.344 260.162 77.472 263.138 82.848C266.21 88.224 267.746 94.656 267.746 102.144V103.872C267.746 111.264 266.258 117.696 263.282 123.168C260.306 128.544 256.274 132.72 251.186 135.696C246.098 138.576 240.386 140.016 234.05 140.016C228.962 140.016 224.594 139.344 220.946 138C217.394 136.56 214.466 134.736 212.162 132.528C209.954 130.32 208.226 128.064 206.978 125.76H205.25V166.8H196.61ZM232.034 132.24C240.002 132.24 246.434 129.696 251.33 124.608C256.322 119.52 258.818 112.512 258.818 103.584V102.432C258.818 93.504 256.322 86.496 251.33 81.408C246.434 76.32 240.002 73.776 232.034 73.776C224.162 73.776 217.73 76.32 212.738 81.408C207.746 86.496 205.25 93.504 205.25 102.432V103.584C205.25 112.512 207.746 119.52 212.738 124.608C217.73 129.696 224.162 132.24 232.034 132.24Z"
      fill="#212121"
    />
    <path
      d="M317.495 140.016C309.047 140.016 301.799 138 295.751 133.968C289.799 129.936 286.151 123.264 284.807 113.952L293.303 112.368C294.071 117.456 295.607 121.488 297.911 124.464C300.311 127.344 303.191 129.408 306.551 130.656C310.007 131.904 313.655 132.528 317.495 132.528C323.255 132.528 327.863 131.376 331.319 129.072C334.871 126.672 336.647 123.408 336.647 119.28C336.647 115.056 334.967 112.128 331.607 110.496C328.343 108.864 323.975 107.568 318.503 106.608L311.879 105.456C307.463 104.688 303.479 103.536 299.927 102C296.375 100.368 293.543 98.208 291.431 95.52C289.415 92.832 288.407 89.472 288.407 85.44C288.407 79.392 290.759 74.64 295.463 71.184C300.263 67.728 306.599 66 314.471 66C322.439 66 328.823 67.824 333.623 71.472C338.519 75.024 341.591 80.208 342.839 87.024L334.775 88.752C333.815 83.184 331.511 79.248 327.863 76.944C324.311 74.64 319.847 73.488 314.471 73.488C309.191 73.488 304.967 74.496 301.799 76.512C298.631 78.528 297.047 81.504 297.047 85.44C297.047 89.28 298.487 92.112 301.367 93.936C304.247 95.664 308.183 96.96 313.175 97.824L319.799 98.976C324.599 99.744 328.919 100.896 332.759 102.432C336.599 103.872 339.623 105.936 341.831 108.624C344.135 111.312 345.287 114.864 345.287 119.28C345.287 125.904 342.791 131.04 337.799 134.688C332.807 138.24 326.039 140.016 317.495 140.016Z"
      fill="#212121"
    />
    <path
      d="M365.493 113.52V37.2H374.421V113.52H365.493ZM369.957 140.016C367.557 140.016 365.589 139.248 364.053 137.712C362.517 136.176 361.749 134.208 361.749 131.808C361.749 129.408 362.517 127.44 364.053 125.904C365.589 124.368 367.557 123.6 369.957 123.6C372.357 123.6 374.325 124.368 375.861 125.904C377.397 127.44 378.165 129.408 378.165 131.808C378.165 134.208 377.397 136.176 375.861 137.712C374.325 139.248 372.357 140.016 369.957 140.016Z"
      fill="#212121"
    />
    <path
      d="M77 111L85.8862 111C85.8862 127.017 72.7298 140 56.5002 140L38.3872 140C22.1576 140 9.0002 127.017 9.0002 111L13.64 111L18 111C18 121.941 27.2999 130.842 38.3863 130.842L56.5002 130.842C67.5865 130.843 77 121.941 77 111Z"
      fill="#212121"
    />
    <path
      d="M18 64L9 64C9 47.9831 22.1564 35 38.3861 35L56.613 35C72.8426 35 86 47.9831 86 64L81.6935 64L77 64C77 53.0587 67.7003 44.1584 56.6139 44.1584L38.3861 44.1584C27.2997 44.1574 18 53.0587 18 64Z"
      fill="#2727FF"
    />
    <circle cx="33.9717" cy="13.25" r="7.25" fill="#212121" />
    <circle cx="61.3613" cy="13.25" r="7.25" fill="#212121" />
    <path d="M18 68H9V111H18V68Z" fill="#212121" />
    <path d="M86 68H77V111H86V68Z" fill="#212121" />
  </svg>
)

export default function NotFoundPage({ pageContext }) {
  const { t } = useTranslation()

  const goToHome = () => {
    navigate(linkResolver(undefined, pageContext.lang, "/"))
  }

  return (
    <>
      <SEO />
      <ModalProvider>
        <main className="bg-positive">
          {/*<FluidBackground maxOrbs={5} />*/}
          <Section container background="positive" className="min-h-screen">
            <div className="grid grid-cols-4 md:grid-cols-12 gap-0 md:gap-8 pt-16 mb-48 md:mb-40 items-center">
              <div className="col-span-1 md:col-span-2">
                <Logo width="full" baseColor="negative" circleColor="blue" />
              </div>

              <div className="col-span-3 md:col-start-10 flex items-center justify-end gap-8 z-10">
                <ContactButton />
                <LanguageSwitch />
              </div>
            </div>

            <div className="grid grid-cols-4 md:grid-cols-12 gap-8">
              <div className="col-span-3 md:col-start-2 md:col-span-5">
                <Oups />
              </div>
            </div>

            <div className="grid grid-cols-4 md:grid-cols-12 gap-8 mt-14">
              <div className="col-start-2 col-span-3 md:col-start-6 md:col-span-4">
                <h2 className="font-base text-24 font-bold">
                  {t("404.subtitle")}
                </h2>
              </div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-12  gap-8 mt-3 md:mt-4">
              <div className="col-start-2 col-span-3 md:col-start-6 md:col-span-5">
                <p className="font-base text-18">{t("404.paragraph")}</p>
              </div>
            </div>

            <div className="grid grid-cols-4 md:grid-cols-12 gap-8 mt-10 mb-48">
              <div className="col-start-2 col-span-2 md:col-start-6 md:col-span-3">
                <button
                  onClick={goToHome}
                  className="w-full uppercase font-base text-14 bg-blue rounded-full text-white py-4"
                >
                  {t("404.goToHome")}
                </button>
              </div>
            </div>
          </Section>
          <Footer />
        </main>
      </ModalProvider>
    </>
  )
}
